$(document).ready(function () {
	$('.nl-news__readmore').readmore({
		speed: 75,
		moreLink: '<a href="#" class="nl-news__moreNews">еще новости</a>',
		lessLink: '<a href="#" class="nl-news__moreNews">свернуть</a>'
	});
	$('.nl-tag__readmore').readmore({
		speed: 75,
		moreLink: '<a href="#" class="nl-news__moreNews">еще новости</a>',
		lessLink: '<a href="#" class="nl-news__moreNews">свернуть</a>'
	});
})