$(document).ready(function () {
	var burger = $('.nl-header__burgerWrp'),
		close = $('.nl-header__link--close'),
		menu = $('.nl-header__list'),
		searchBtn = $('.nl-header__searchIcon'),
		searchInput = $('.nl-header__search');

		burger.on('click',function () {
			TweenMax.to(menu, 0.6, {css:{"top":"0"}})
		})
		close.on('click',function () {
			TweenMax.to(menu, 0.6, {css:{"top":"-120%"}})
		})
})